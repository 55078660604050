import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { useUpdateOTLds } from '@phx-husky/use-update-ot-lds';

import { Container, Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Typography from 'common/Typography';

import './LdsPage.scss';

const LdsPage: FC<LdsPageTypes.LdsProps> = ({
  data: {
    lds: { urls, seo, body, versionId, platform, businessId },
    fontsToPreload,
  },
}) => {
  const { title, keywords, description } = seo;

  const { updatedLdsContent, isCheckingLdsVersion } = useUpdateOTLds({
    baseUrl: process.env.GATSBY_OT_LDS_BASE_URL as string,
    businessId,
    versionId,
    platform,
  });

  return (
    <Layout>
      <Seo {...{ urls, seo }} fontsToPreload={fontsToPreload?.nodes} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      {!isCheckingLdsVersion && (
        <Container className="lds-page__container">
          <Typography dangerouslySetInnerHTML={updatedLdsContent ?? body} />
        </Container>
      )}
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String, $path: [String], $fonts: [String] = []) {
    lds(lang: { eq: $lang }, url: { in: $path }) {
      seo {
        ...SEOStructureFragment
      }
      urls {
        ...UrlsFragment
      }
      body
      versionId
      platform
      businessId: businessID
    }
    fontsToPreload: allFile(filter: { relativePath: { in: $fonts } }) {
      nodes {
        publicURL
      }
    }
  }
`;

export default LdsPage;
